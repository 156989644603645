<template>
  <v-dialog persistent 
    v-model="activeModal"
    :max-width="variables.widthModal"
    :min-height="'500px'"

    
    class="modal-cadastro">
    <template v-slot:activator="{}">
      <!-- <v-btn
        tile
        :color="variables.colorPrimary"
        dark class="mb-2 modal-cadastro-btn-cadastrar"
        @click="openModal"
        :loading="loading"
        v-if="permiteAcao($route, 'add')">Novo cadastro</v-btn> -->
    </template>
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      
      <v-form>
        <v-card-title class="modal-cadastro-titulo fechado" v-if="!isValidateDate">
          Concurso em execução
        </v-card-title>

        <v-card-title class="modal-cadastro-titulo">
          Cadastrar resultado:  {{ sweepstakes.dtDraw | datas('DD/MM/YYYY') }} - {{ sweepstakes.cod }} <br>
        </v-card-title>

        <v-card-text class="modal-cadastro-form">
          <v-row>
            <v-col class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loadedPrizes">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>

            <v-col v-if="!loadedPrizes">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Descrição do prêmio</th>
                      <th class="text-left">Resultado</th>
                    </tr>
                  </thead>
                  <tbody>

                      <template v-if="!listPrizes[0]">
                        <td>Consurso está sem prêmios</td>
                      </template>
                      <tr v-for="(item, index) in form" :key="index">
                        <td>{{ item.prizeDescription }}</td>
                        <td>
                          <v-text-field 
                            :single-line="true"
                            :dense="true"
                            hide-details="auto"
                            style="max-width: 150px !important"
                            v-model="item.data.number" 
                            :outlined="true"
                            class="results-campo" 
                            :disabled="item.data.registered"
                            :error-messages="errorMensagens(index)"
                             @input="$v.form[index].data.number.$touch()" @blur="$v.form[index].data.number.$touch()"
                            v-mask="formatMask(item.drawType)"/>
                        </td>
                      </tr>

                      <tr v-if="!sweepstakes.resultRecord && formSize(form) > 1">
                        <td>Soma de todos os resultados</td>
                        <td>
                          <v-text-field 
                            :single-line="true"
                            :dense="true"
                            hide-details="auto"
                            style="max-width: 150px !important"
                            v-model="total" 
                            :outlined="true"
                            class="results-campo"/>
                        </td>
                      </tr>

                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="modal-cadastro-footer">
          <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
          <v-btn
          :disabled="!isValidateDate"
            type="submit"
            :color="variables.colorPrimary"
            @click.prevent="submit"
            class="br-btn"
            :loading="loading"
            v-if="!sweepstakes.resultRecord">{{ isEdit ? 'Salvar' : 'Cadastrar' }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// eslint-disable-next-line
import { required, minValue, maxValue, minLength } from 'vuelidate/lib/validators'
// import Events from '@/core/service/events'
import { mask } from 'vue-the-mask'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import { filter, reduce, size, each, map, range, uniqBy, compact } from 'lodash'

// import validacao from './validacao'

export default {
  name: 'ModalCadstroResultado',
  mixins: [validationMixin],
  directives: {mask},
  data: () => ({
    activeModal: false,
    loading: false,
    menu: false,
    datetime: new Date(),
    loadedPrizes: false,
    headers: [
      { align: 'start', class: 'table-header', text: 'Descrição do prêmio', value: 'prizeDescription' },
      { align: 'start', class: 'table-header', text: 'Resultado', value: 'results' },
    ],
    form: {},
    prizesWithResults: [],
    total: ''
  }),
  watch: {
    async 'sweepstakes.id' (id)  {
      const vm = this
      if (id >= 0) {
        this.loadedPrizes = true
        this.activeModal = true
        const prizes = await this.getPrizes({ sweepstakeId: id })
        const results = await this.getItens({ _filter: { "sweepstakeId_=": id } })
        Promise
          .all([prizes, results])
          .then((result) => {
            let multiplosPremios = vm.formatListPrizes(result)
            this.form = {}
            each(result[0].data, (v, k) => {
              let aux = v
              if (k === 'date') return
              aux.results = filter(result[1].data, itemFilter => { 
                return v.id === itemFilter.prizeId
              })
              if (multiplosPremios.length !== 1) {
                this.setResultConfigPremios(aux, k)
              }
              else if (aux.drawType === 'Decimals' && aux.results.length !== 0 && multiplosPremios.length === 1) { // se for decimal e tiver resultado e nao tiver variacao de typos de premios set dados form
                  this.setResult(aux)
              }
              else {
                this.prizesWithResults.push(aux)
              }
            })
          })
          .catch((err) => errorSnackbar(err.error))
          .finally(() => this.loadedPrizes = false)
      }
    },

    prizesWithResults (prizes) {
      const self = this
      prizes.forEach((item, index) => {
        if (item.drawType === 'Decimals') {
          let rangeObject = range(0, parseInt(item.drawConfig.qtInputs)) // gera quantidade de inputs
          rangeObject.forEach((obj, key) => {
            this.$set(self.form, `item${key}`, this.geraForm(item))
          })
        } else {
          this.$set(self.form, `item${index}`, this.geraForm(item))
        }
      })
      // this.prizesWithResults = 
    }
  },

  computed: {
    ...mapGetters('filter', ['objFilter']),
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('results', ['item', 'getDate']),
    ...mapGetters('sweepstakes', {
      sweepstakes: 'item'
    }),
    ...mapGetters('prizes', {
      listPrizes: 'listaItens'
    }),
    isValidateDate () {
      const date = this.getDate
      if (date === '' || !this.sweepstakes.dtDraw) return false
      return moment(date).isAfter(this.sweepstakes.dtDraw)
    },
    listPrizesFormat () {
      let obj = this.listPrizes
      return  compact(map(obj, (v, k) => k !== 'date'? v : null ))
    },
    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    },
    isPrognostico () {
      return this.sweepstakes.ascertainedMethodId
    }
  },

  methods: {
    formatListPrizes (obj) {
      let formatResult = compact(map(obj[0].data, (v, k) => k !== 'date'? v : null ))
      let multiplosPremios = uniqBy(formatResult, v => v.drawType)
      return multiplosPremios
    },
    // tipos de premios decimal e null ou outro tipo
    setResultConfigPremios (v, k) { 
      const vm = this
      this.$set(this.form, `item${k}`, {
        sweepstakeId: vm.sweepstakes.id,
        dtDraw: v.dtDraw,
        prizeId: v.id,
        prizeDescription: v.description,
        drawType: v.drawType,
        data:  {
          number: v.results.length ? v.results[k].data.number : '',
          registered: v.results.length ? true : false
        }
      })
    },
    setResult (obj) {
      const vm = this
      let format =  String(obj.results[0].data.decimals).match(new RegExp('.{' + obj.drawConfig.qtInputs + '}', 'g'))
      this.form = {}
      each(format, (v, k) => {
        this.$set(vm.form, `item${k}`, {
          dtDraw: v.dtDraw,
          sweepstakeId: vm.sweepstakes.id,
          prizeId: obj.id,
          prizeDescription: obj.description,
          drawType: obj.drawType,
          data:  {
            number: v,
            registered: true
          }
        })
      })
    },
    errorMensagens (index) {
      let error = []
      if (!this.$v.form[index]) return []
      if (!this.$v.form[index].$dirty) return []
      !this.$v.form[index].data.number.required && error.push('Número inválido')
      !this.$v.form[index].data.number.minValue && error.push('Número inválido')
      !this.$v.form[index].data.number.maxValue && error.push('Número inválido')
      !this.$v.form[index].data.number.minLength && error.push('Número inválido')
      return error
    },
    formatMask (v) {
      if (v === 'Decimals') return ['##']
      return ['####']
    },
    geraForm (v) {
      return {
        dtDraw: v.dtDraw,
        sweepstakeId: this.sweepstakes.id,
        prizeId: v.id,
        prizeDescription: v.description,
        drawType: v.drawType,
        data:  {
            number: v.results.length ? v.results[0].data.number : '',
            registered: v.results.length ? true : false
        }
      }
    },
    ...mapActions('results', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem']),
    ...mapActions('results', {
      getSweepstakes: 'getSweepstakes'
    }),
    ...mapActions('sweepstakes', {
      clearSweepstakes: 'limparItem'
    }),
    ...mapActions('prizes', {
      getPrizes: 'obterItens',
      clearPrizes: 'limparItem'
    }),
    openModal () {
      this.activeModal = true
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.clearSweepstakes()
      this.clearPrizes()
      this.prizesWithResults = []
      this.total = ''
      this.$v.$reset()
      this.$v.form && this.$v.form.$reset()
      
    },

    formatDate (date) {
      moment().locale(this.$store.getters.Locale)
      if (!date) return null
      return moment(date).format('DD/MM/YYYY')
    },
    ValidateTotal (total) {
       if (this.total === '' || !this.total) return true
       if (this.total !== "" && parseInt(this.total) !== parseInt(total)) return true
      return false
    },
    submit () {
      if (this.$v.form.$invalid) { 
        this.$v.form.$touch()
        return false 
      }
      this.loading = true
      
      let total = reduce(this.form, (acumulador, valorAtual) => {
        return acumulador + parseInt(valorAtual.data.number)
      }, 0)

      const prizesFiltered = filter(this.form, item => {
        return !item.data.registered
      })

      if (this.ValidateTotal(total)) {
          this.$swal({
          icon: 'error',
          text: `Valores cadastrado não bate com o valor total`,
          showCancelButton: false
        })
        this.loading = false
        return
      }

      let dados = []

      prizesFiltered.forEach(item => {
        const aux = {
          dtDraw: moment(item.dtDraw, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
          sweepstakeId: item.sweepstakeId,
          prizeId: item.prizeId,
          data: ((v, type) => {
            if (type[0].drawType === 'Decimals') return { decimals: v.data.number }
            return { number: v.data.number }
          })(item, this.sweepstakes.prizes)
        }

        dados.push(aux)
      })

      if (!dados.length) {
        this.$swal({
          icon: 'error',
          text: `Não teve dados novos regitrado!`,
          showCancelButton: false
        })

        this.loading = false

        return
      }

      let formatDadosMax = [dados[0]]
      if (dados[0].data.decimals !== undefined) {
        formatDadosMax[0].data =  {max : map(dados, v => v.data.decimals).join('')}
        this.cadastroResultado(formatDadosMax)
      } else {
        this.cadastroResultado(dados)
      }
    },
    verificaFiltros () {
      const vm = this
      let obj = vm.objFilter._addlFilter === undefined ? vm.objFilter : vm.objFilter._addlFilter
      let filtro = Object.keys(obj).join('')
      if (filtro.indexOf('Sweepstakes:dt') === -1 && filtro.indexOf('cod') === -1 && filtro.indexOf('cod')) {
        return {
          'Sweepstakes:dtDraw_>': moment().format('YYYY-MM-DD'),
          'Sweepstakes:dtDraw_<=': moment().add(1, 'days').format('YYYY-MM-DD')
        }
      } else {
        return vm.objFilter._addlFilter 
      }
    },
    cadastroResultado (dados) {
      const vm = this
      const filtrosDefault = vm.verificaFiltros()
       this.cadastrarItem(dados)
        .then(() => {
          vm.closeModal()
          vm.getSweepstakes({ 
            page: vm.page, 
            pageSize: vm.$store.getters.ItensPaginacao,
            order: {'Sweepstakes.dtDraw':'ASC'},
             _addlFilter: {
               'Sweepstakes:automaticDraw_=' : false,
               ...vm.objFilter._addlFilter,
               ...filtrosDefault
             } })
          successSnackbar('Resultados cadastrados com sucesso!')
        })
        .catch(err => errorSnackbar(err.error))
        .finally(() => vm.loading = false)
    },
    formSize (form) {
      return size(form)
    }
  },

  validations () {
    const vm = this
    // const args = { item: {} }
      let multiplosPremios = uniqBy(vm.listPrizesFormat, v => v.drawType)
      let items = {}
      let obj = {}
      if (!this.listPrizes[0] || size(this.form) === 0) return {}
      let qtMin = vm.listPrizesFormat[0].drawConfig === null ? 1 : vm.listPrizes[0].drawConfig.qtRangeMin
      let qtMax = vm.listPrizesFormat[0].drawConfig === null ? 25 : vm.listPrizes[0].drawConfig.qtRangeMax
      let min = vm.listPrizesFormat[0].drawConfig === null ? 4 : vm.listPrizes[0].drawConfig.qtDigPalp
      if (multiplosPremios.length > 1) {
        obj = compact(map(vm.form, (v, k) => {
          if(k === 'date') return null
          let key = parseInt(String(k).replace(/\D+/g, ''))
          return { data: {
            number: {
              required,
              minValue: minValue(vm.listPrizesFormat[key].drawConfig.qtRangeMin),
              maxValue: maxValue(vm.listPrizesFormat[key].drawConfig.qtRangeMax),
              minLength: minLength(vm.listPrizesFormat[key].drawConfig.qtDigPalp)
            }
          }
        }}))
        obj.forEach((v, k) => {
          items[`item${k}`] = v
        })
      }

      if (multiplosPremios.length === 1) {
        obj = map(vm.form, () => ({
            data: {
              number: {
                required,
              minValue: minValue(qtMin),
              maxValue: maxValue(qtMax),
              minLength: minLength(min)
            }
          }
        }))
        obj.forEach((v, k) => {
          items[`item${k}`] = v
        })
      }
      // return args.item = { required, minLenght: minLenght(14)}
    return  { form:  items }
  }
}
</script>

<style lang="scss">
  @import '../../../../../assets/styles/components/formularios';
  @import '../../../../../assets/styles/components/modal-cadastro';
  .v-input__control{
    padding: 15px 0px;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details .v-messages {
    position: relative;
    bottom: -0px; 
  }
  .results-campo {
    .v-input__slot {
      margin-bottom: 0 !important;
      min-height: 30px !important;
    }
  }
</style>